<template>
  <div>
    <!-- self-assessment signup start -->
    <section class="self_sec">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6">
            <div class="slef_assess_content py_100 mt_10">
              <div>
                <h2 class="mb_0">Have you taken your</h2>
                <h2 class="mb_48">self-assessment?</h2>
                <p class="mb_60">
                  Whether you are experiencing discomfort or just curious about
                  ergonomics, this is the place to start! Taking the self
                  assessment will improve your productivity and help you get rid
                  of (or avoid) pesky aches and pains.
                  <span class="font_bold"
                    >Give us 10 minutes of your time, we will give you a better
                    you.</span
                  >
                </p>
                <router-link
                  :to="{ name: 'self_assessment' }"
                  class="btn btn_primary mb_120"
                  >Start Your Self-Assessment</router-link
                >
              </div>
              <div v-if="company_data.schedule_request_status">
                <h2 class="mb_0 mt_2">Already completed a</h2>
                <h2 class="mb_24 mt_2">self-assessment?</h2>
                <p class="mb_100 pb_4" v-html="company_data.schedule_request">
                </p>
                <router-link
                  :to="{ name: 'schedule_eval' }"
                  class="btn btn_primary"
                  >Schedule an Evaluation</router-link
                >
              </div>
            </div>
          </div>
          <div class="col-md-6 p-0">
            <div class="self_img">
              <img src="../assets/images/self_assess.png" alt="img" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- sel assessment signup snd -->
  </div>
</template>
<script>
export default {
  name: "Self_assessment_signup",
  data() {
    return {
      company_data: {
         schedule_request_status:true,
         schedule_request:`Do you need a bit more help after taking your self-assessment?
                  We’ve got you covered. Schedule a one-on-one ergo evaluation
                  with one of our leading ergonomists.`
      },
    };
  },
  mounted() {
    document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
    // let company_data = JSON.parse(localStorage.getItem("companyInformation"));
    let data = this.$store.getters["auth/authUser"];
    let company_data = data.client;
    if (company_data == null) {
      company_data = [];
    }
    if (company_data.length == 0 || company_data.length === "undefined") {
      console.log("no company values home");      
    } else {
      this.company_data.schedule_request_status = company_data.schedule_request_status;
      this.company_data.schedule_request = company_data.schedule_request;
    }
  },
};
</script>